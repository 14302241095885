<template>
  <div class="wrapper">
    <Icon
      name="logo"
      class="image-logo"
      :width="`${breakpoints.isMobile ? 85 : 130}px`"
      :height="`${breakpoints.isMobile ? 85 : 120}px`"
      fill="white"
    ></Icon>
    <div class="average-rating">{{ averageReview }} / 10</div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  averageReview?: number;
}>();
const breakpoints = useBreakpoints();
</script>
<style lang="scss" scoped>
@use '$/breakpoints.scss';

.wrapper {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;

  @include breakpoints.mobile() {
    margin-top: 10px;
  }

  .average-rating {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 85px;
    height: 85px;

    font-size: 1.2em;
    font-weight: 700;

    background-color: white;
    border-radius: 45px;

    @include breakpoints.mobile() {
      width: 65px;
      height: 65px;
      font-size: 1em;
    }
  }
}
</style>