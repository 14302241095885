<template>
  <div :class="{ largeheader: isLargeHeader, mini: mini }" class="hero-journey">
    <div class="image-stack">
      <Transition name="fade">
        <NuxtImg
          v-if="images[image]?.url"
          :key="image"
          :src="images[image]?.url"
          :alt="images[image]?.title"
          provider="twicpics"
          class="image"
          format="webp"
          :width="breakpoints.isMobile ? 400 : 1905"
          :height="breakpoints.isMobile ? 295 : 710"
          fit="cover"
        />
      </Transition>
      <div class="dark-overlay" />
      <div class="content">
        <div class="title-container">
          <h1 :class="{ largeheader: isLargeHeader }" class="title">{{ title }}</h1>
          <p :class="{ largeheader: isLargeHeader }" class="subtitle">{{ subtitle }}</p>
          <p v-if="heroText" class="text">{{ heroText }}</p>
          <div v-if="averageReview" class="average-card">
            <AverageCard :average-review="averageReview"></AverageCard>
          </div>
        </div>
        <div class="searchbar-content">
          <GlobalSearchInput
            v-if="isHomepage"
            class="searchbar"
            :placeholder-text="inputPlaceholder ?? 'rechercher'"
          />
          <Icon v-if="inputPlaceholder" alt="or" class="or" name="or" />
          <Icon v-if="inputPlaceholder" alt="or" class="or-mobile" name="or-mobile" />
          <NuxtLink v-if="cta" class="inspire" :to="`${cta.url}/`">
            <div class="message">
              <span> {{ cta.label }}</span>
              <div v-if="inputPlaceholder">
                <Icon alt="globe" class="globe" name="globe" />
              </div>
              <div v-else class="cta" />
            </div>
          </NuxtLink>
        </div>
      </div>
    </div>

    <div class="top-line">
      <div
        v-if="breakpoints.isMobile && diaporama && images.length > 1 && !noControl"
        class="switch-arrows"
      >
        <div class="left" @click="previousImage">
          <Icon name="arrow-circle-left" />
        </div>
        <div class="right" @click="nextImage">
          <Icon name="arrow-circle-right" />
        </div>
      </div>
      <!-- <Icon
        v-if="journey"
        :class="{ clicked: like }"
        class="like"
        :name="like ? 'heart-active' : 'heart-inactive'"
        @click="like = !like"
      />-->
    </div>

    <div class="bottom">
      <div class="socials-container">
        <div class="socials">
          <NuxtLink
            v-for="social of common?.socials"
            :key="social.icon"
            :to="social.url"
            class="social"
            target="_blank"
          >
            <Icon v-if="social.icon" :name="social.icon" />
          </NuxtLink>
        </div>
        <div class="separator" />
        <div class="text">Nos réseaux sociaux</div>
      </div>

      <div :class="{ 'no-journey': !journey }" class="info">
        <template v-if="journey">
          <div class="left">
            <div class="duration">
              <div class="days">{{ journey.attributes.days }} jours</div>
              <div class="nights">{{ journey.attributes.nights }} nuits</div>
              <div class="point" />
              <div class="ratings">0 avis</div>
            </div>
            <div class="price">
              À partir de {{ journey.attributes.price?.toLocaleString('fr') }} €
              {{
                getBasePriceHeadlineValues(journey.attributes.basePriceHeadline ?? 0, true)['stars']
              }}
            </div>
          </div>
          <div class="middle">
            <Icon class="pin" height="22px" name="map-pin" width="18px" />

            <div class="locations" @click="anchorTo('map')">{{ placesName }}</div>
          </div>
          <div class="price-big">{{ journey.attributes.price?.toLocaleString('fr') }} €*</div>
        </template>
        <div class="right">
          <div v-if="journey" class="ratings">0 avis voyageurs</div>
          <div v-if="diaporama && images.length > 1 && !noControl" class="slider">
            <div class="up">
              <div class="location">
                <Icon name="map-pin" />
                {{ images[image]?.title }}
              </div>
              <div v-if="!breakpoints.isMobile" class="switch-arrows">
                <div :class="{ disabled: image === 0 }" class="left" @click="previousImage">
                  <Icon name="arrow-circle-left" />
                </div>
                <div
                  :class="{ disabled: image >= images.length - 1 }"
                  class="right"
                  @click="nextImage"
                >
                  <Icon name="arrow-circle-right" />
                </div>
              </div>
            </div>
            <div class="bar">
              <div :style="{ width: `${(image / (images.length - 1)) * 100}%` }" class="fill" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { MvJourney } from '@/lib/strapi-types/MvJourney';
import { HeroImage } from '@/lib/types/models/common';
import { CtaHero } from '@/lib/types/models/ctaHero';
import { getBasePriceHeadlineValues } from '@/lib/journey';

const { images, placesName, cta, inputPlaceholder } = defineProps<{
  title?: string;
  images: HeroImage[];
  journey?: MvJourney;
  cta?: CtaHero;
  inputPlaceholder?: string;
  diaporama?: boolean;
  noControl?: boolean;
  subtitle?: string;
  placesName?: string;
  mini?: boolean;
  heroText?: string;
  isLargeHeader?: boolean;
  averageReview?: number;
}>();
const rootStore = useRootStore();
const { common } = storeToRefs(rootStore);
const CHANGE_DELAY = 5000;
let image = $ref(0);
let timeout = $ref<NodeJS.Timeout>();

// const like = $ref(false);
const isHomepage = cta && inputPlaceholder;
function anchorTo(id: string): void {
  if (id.length > 0) window.location.hash = `#${id}`;
}

const breakpoints = useBreakpoints();

onBeforeMount(() => {
  for (const image of images.slice(1)) {
    image.url && fetch(image.url);
  }
  resetTimer();
});

onBeforeUnmount(() => clearTimeout(timeout));

function resetTimer(): void {
  if (timeout) {
    clearTimeout(timeout);
  }

  timeout = setTimeout(() => {
    if (image >= images.length - 1) {
      image = 0;
    } else {
      image += 1;
    }

    resetTimer();
  }, CHANGE_DELAY);
}

function nextImage(): void {
  image = Math.min(image + 1, images.length - 1);
  resetTimer();
}

function previousImage(): void {
  image = Math.max(image - 1, 0);
  resetTimer();
}
</script>

<style lang="scss" scoped>
@use 'sass:color';
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/fonts.scss';
@use '$/text-ellipsis.scss';
@use '$/mouse.scss';

.hero-journey {
  $mobile-info-height: 54px;
  $mobile-top-line-margin-top: 18px;

  position: relative;
  flex-direction: column;
  justify-content: space-between;
  height: 450px;

  @include breakpoints.mobile() {
    height: 295px;
  }

  &.largeheader {
    height: 710px;

    @include breakpoints.mobile() {
      height: 295px;
    }
  }

  .image-stack {
    @include breakpoints.mobile() {
      // height: calc(100% - #{$mobile-info-height}) !important;
    }

    &,
    & > * {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
    }

    .image {
      z-index: -1;
      object-fit: cover;

      &.fade-enter-active,
      &.fade-leave-active {
        transition: opacity 0.5s ease;
      }

      &.fade-enter-from,
      &.fade-leave-to {
        opacity: 0;
      }
    }

    .dark-overlay {
      z-index: -1;
      width: 100%;
      height: 100%;
      background: rgb(0 0 0 / 40%);
    }

    .content {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include breakpoints.mobile() {
        padding: 16px;
      }

      .title-container {
        flex-direction: column;
        align-content: center;

        .average-card {
          display: flex;
          justify-content: center;
        }

        .title {
          font-size: 60px;
          font-weight: bold;
          color: white;
          text-align: center;

          @include breakpoints.mobile() {
            font-size: 30px;
            line-height: 33px;
            white-space: pre-line;
          }

          &::first-letter {
            font-family: fonts.$marco-vasco;
          }

          &.largeheader {
            font-size: 75px;

            @include breakpoints.mobile() {
              font-size: 30px;
              line-height: 33px;
              white-space: pre-line;
            }
          }
        }

        .subtitle {
          font-size: 60px;
          font-weight: bold;
          color: white;
          text-align: center;

          @include breakpoints.mobile() {
            font-size: 30px;
            line-height: 33px;
            white-space: pre-line;
          }

          &.largeheader {
            font-size: 75px;

            @include breakpoints.mobile() {
              font-size: 30px;
              line-height: 33px;
              white-space: pre-line;
            }
          }
        }

        .text {
          align-self: center;

          padding-top: 12px;

          font-size: 30px;
          color: white;
          text-align: center;

          @include breakpoints.mobile() {
            width: 100%;
            font-size: 20px;
          }
        }
      }

      .searchbar-content {
        padding-top: 48px;

        @include breakpoints.mobile() {
          flex-direction: column;
          width: 100%;
          padding-top: 32px;
        }

        .searchbar {
          width: 560px;

          @include breakpoints.mobile() {
            width: initial;
          }
        }

        .or {
          padding: 0 32px;

          @include breakpoints.mobile() {
            display: none;
          }
        }

        .or-mobile {
          display: none;

          @include breakpoints.mobile() {
            display: block;
            align-self: center;
            margin: 8px 0;
          }
        }

        .inspire {
          align-items: center;

          height: 72px;
          padding: 0 23px;

          font-size: 18px;
          font-weight: 400;

          background: colors.$primary-yellow;
          border-radius: 70px;

          @include breakpoints.mobile() {
            align-self: center;
            justify-content: center;

            width: 187px;
            height: 56px;

            font-size: 16px;
          }

          .message {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            .cta {
              margin-left: 1rem;
              font-size: 2rem;
            }
          }

          .globe {
            width: 30px;
            margin-left: 20px;

            @include breakpoints.mobile() {
              width: 20px;
            }

            &:deep(svg path) {
              fill: black;
            }
          }
        }
      }
    }
  }

  .top-line {
    z-index: 1;

    gap: 14px;
    align-items: center;
    justify-content: flex-end;

    padding: 28px 42px;

    @include breakpoints.mobile() {
      justify-content: space-between;
      padding: $mobile-top-line-margin-top 17px;
    }

    .switch-arrows {
      display: flex;
      justify-content: space-around;
      width: 90px;

      @include breakpoints.mobile() {
        .left,
        .right {
          @include mouse.clickable-svg(stroke, white, white, colors.$primary-red);

          :deep(svg) {
            @include breakpoints.mobile() {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }

    /*  .like {
      @include mouse.clickable-svg(
        $color-base: transparent,
        $color-hover: color.change(colors.$primary-black, $alpha: 0.7),
        $color-active: colors.$primary-red
      );
      @include mouse.clickable-scale();

      z-index: 100;

      &:deep(svg) {
        @include breakpoints.mobile() {
          width: 22px;
          height: 19px;

          path {
            stroke-width: 2;
          }
        }
      }
    } */
  }

  .bottom {
    gap: 40px;
    align-items: flex-end;
    justify-content: space-between;

    .socials-container {
      flex-direction: column;
      gap: 7px;
      margin-bottom: 17px;
      margin-left: 40px;

      @include breakpoints.mobile() {
        display: none;
      }

      .socials {
        z-index: 1;
        gap: 22px;
        margin-bottom: 3px;

        .social {
          @include mouse.clickable-svg($color-base: white);

          display: flex;
        }
      }

      .separator {
        width: 100%;
        border-top: 2px dashed white;
      }

      .text {
        font-size: 13px;
        line-height: 16px;
        color: white;
      }
    }

    .info {
      $width: 1120px;
      $width-content: 1090px;
      $height: 90px;

      align-items: center;
      justify-content: space-between;

      width: 1120px;
      height: 90px;
      padding: 20px 40px;

      color: white;

      background-color: colors.$primary-black;
      clip-path: polygon($width - $width-content 0, 0 $height, $width $height, $width 0);

      @include breakpoints.mobile() {
        display: grid;
        grid-template-columns: repeat(2, auto);

        width: 100%;
        height: $mobile-info-height;
        padding: 10px 16px;

        clip-path: none;
      }

      &.no-journey {
        justify-content: flex-end;
        background: none;
      }

      .left {
        flex-direction: column;
        gap: 4px;
        margin-left: 25px;

        @include breakpoints.mobile() {
          grid-column: 1 / 3;
          margin-left: 0;
        }

        .duration {
          gap: 13px;
          font-size: 15px;
          font-weight: 600;

          @include breakpoints.mobile() {
            gap: 8px;
            align-items: center;

            font-size: 12px;
            font-weight: 700;
            line-height: 14px;
          }

          .point,
          .ratings {
            display: none;

            @include breakpoints.mobile() {
              display: flex;
            }
          }

          .point {
            width: 2px;
            height: 2px;
            background-color: white;
            border-radius: 50%;
          }

          .ratings {
            text-decoration: underline;
            text-underline-offset: 3px;
          }
        }

        .price {
          font-size: 20px;

          @include breakpoints.mobile() {
            display: none;
          }
        }
      }

      .middle {
        gap: 11px;
        align-items: center;

        @include breakpoints.mobile() {
          gap: 6px;
        }

        .pin:deep(svg) {
          @include breakpoints.mobile() {
            width: 9px !important;
            height: 11px !important;
          }
        }

        .locations {
          @include text-ellipsis.single-line();

          z-index: 10000;
          width: 250px;
          font-size: 20px;
          line-height: 24px;

          @include breakpoints.mobile() {
            @include text-ellipsis.single-line();

            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
          }
        }
      }

      .price-big {
        display: none;

        @include breakpoints.mobile() {
          transform: translateY(-10px);

          display: flex;

          font-size: 20px;
          font-weight: 900;
          line-height: 24px;
          color: colors.$primary-yellow;
        }
      }

      .right {
        gap: 35px;
        align-items: center;

        *:not(.slider, .up, .switch-arrows, .switch-arrows *) {
          @include breakpoints.mobile() {
            display: none;
          }
        }

        .ratings {
          padding: 8px 10px;

          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          color: colors.$primary-black;
          text-decoration: underline;
          text-underline-offset: 4px;

          background-color: colors.$primary-yellow;
          border-radius: 6px;
        }

        .slider {
          flex-direction: column;
          gap: 13px;
          width: 200px;

          .up {
            align-items: center;
            justify-content: space-between;

            .location {
              gap: 6px;
              align-items: center;
              font-size: 13px;
            }

            .switch-arrows {
              gap: 8px;

              @include breakpoints.mobile() {
                position: absolute;
                top: 250px;
                right: 0;

                gap: 15px;

                margin-right: 12px;
              }

              .left,
              .right {
                @include mouse.clickable-svg(stroke, $color-base: white);

                :deep(svg) {
                  @include breakpoints.mobile() {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }
          }

          .bar {
            height: 2px;
            background-color: white;

            .fill {
              background-color: colors.$primary-yellow;
              transition: width 0.25s;
            }
          }
        }
      }
    }
  }
}

.mini {
  height: 432px;

  @include breakpoints.mobile() {
    height: 267px;
  }
}
</style>
